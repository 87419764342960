<template>
  <div class="justify-center">
    <Matcher
      :i18n_instructionMsg_key="'matchTrimantraInstruction'"
      :matchList="match"
      :numberOfMatches="match.length"
      :fontSize="$vuetify.breakpoint.smAndUp ? '17px' : '10px'"
    />
  </div>
</template>

<script>
import Matcher from './Matcher'

export default {
  components: {
    Matcher
  },

  methods: {},
  data: () => ({
    match: [
      { eng: 'ૐ Namo Vitaragay', guj: 'ૐ નમો વીતરાગાય', hindi: 'नमो वीतरागाय' },
      { eng: 'Namo Arihantanam', guj: 'નમો અરિહંતાણં', hindi: 'नमो अरिहंताणं' },
      { eng: 'Namo Siddhanam', guj: 'નમો સિદ્ધાણં', hindi: 'नमो सिद्धाणं' },
      { eng: 'Namo Aayariyanam', guj: 'નમો આયરિયાણં', hindi: 'नमो आयरियाणं' },
      {
        eng: 'Namo Uvazzayanam',
        guj: 'નમો ઉવ્વજ્ઝાયાણં',
        hindi: 'नमो उवज्झायाणं'
      },
      {
        eng: 'Namo Loye Savva Saahunam',
        guj: 'નમો લોએ સવ્વસાહૂણં',
        hindi: 'नमो लोए सव्वसाहूणं'
      },
      {
        eng: 'Eso Pancha Namukkaro',
        guj: 'એસો પંચ નમુક્કારો',
        hindi: 'एसो पंच नमुक्कारो'
      },
      {
        eng: 'Savva Paavappanasano',
        guj: 'સવ્વ પાવપ્પણાસણો',
        hindi: 'सव्व पावप्पणासणो'
      },
      {
        eng: 'Mangalanam Cha Savvesim',
        guj: 'મંગલાણં ચ સવ્વેસિં',
        hindi: 'मंगलाणं च सव्वेसिं'
      },
      {
        eng: 'Padhamam Havai Mangalam',
        guj: 'પઢમં હવઈ મંગલં',
        hindi: 'पढमं हवई मंगलम्'
      },
      {
        eng: 'ૐ Namo Bhagavate Vasudevaya',
        guj: 'ૐ નમો ભગવતે વાસુદેવાય',
        hindi: 'ॐ नमो भगवते वासुदेवाय'
      },
      { eng: 'ૐ Namah Shivaaya', guj: 'ૐ નમ: શિવાય', hindi: 'ॐ नम: शिवाय' }
    ]
  })
}
</script>
<style></style>
